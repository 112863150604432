import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3, H4 } from '../../components/Headings'
import Container from '../../components/Container'
import { ButtonLink } from '../../components/Button'
import Testimonials from '../../components/Testimonials'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Content from '../../components/Post/Content'

import List from '../../components/List'

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]

  const {
    hero,
    ppsMaxSection,
    interiorTreatmentSection,
    diagrams,
    exteriorTreatmentSection,
    numberedProcess,
    seasonalPestFocus,
    faq,
    footerCta,
  } = page.pestControlServices

  return (
    <>
      <SEO />
      <Layout>
        {/* Hero */}
        <section tw="flex items-center justify-center text-gray-900 relative from-white to-transparent">
          <div tw="overflow-hidden h-full w-full absolute z-0">
            <Img
              fluid={hero.background.localFile.childImageSharp.fluid}
              style={{ position: 'absolute' }}
              css={[
                tw`min-w-full min-h-full max-w-full max-h-full transform -translate-x-1/2 -translate-y-1/2 opacity-75`,
                'z-index: -1; top:50%;left:50%;',
              ]}
            />

            <div
              style={{ position: 'absolute' }}
              css={[
                tw`w-full h-full z-20 opacity-75`,
                `background-image: linear-gradient(169deg, #ffffff 41%, rgba(255, 255, 255, 0.52) 66%, rgba(128, 128, 128, 0) 83%);`,
              ]}
            />
          </div>

          <Container tw="my-20 z-50 relative">
            <H1>{hero.headline}</H1>
            <div tw="font-normal text-2xl mb-4">{hero?.subHeadline}</div>
            <p tw="text-lg mb-5 leading-relaxed mr-auto max-w-2xl">
              {hero?.paragraph}
            </p>
            <ButtonLink to="/quote">{hero.ctaButtonText}</ButtonLink>
          </Container>
        </section>

        <section aria-label="Year-Round Protection">
          <Container>
            {/* PPS Max Service Description */}
            <div tw="py-16">
              <H2 tw="leading-tight mb-0 uppercase">
                {ppsMaxSection.title}{' '}
                <span tw="text-base font-normal normal-case">
                  {ppsMaxSection.frequencyOfService}
                </span>
              </H2>
              <div tw="text-gray-800 text-2xl font-normal mb-8">
                {ppsMaxSection.subHeadline}
              </div>
              <Content
                tw="text-gray-800 w-full mb-8"
                dangerouslySetInnerHTML={{ __html: ppsMaxSection.content }}
              />

              <p tw="italic text-gray-600">{ppsMaxSection.footnote}</p>
            </div>

            {/* Interior Treatment Diagram and Content */}
            <div tw="md:(grid grid-cols-5 gap-16) overflow-x-hidden py-16">
              <div tw="col-span-3">
                <H2 tw="uppercase font-normal mb-5 text-center sm:(text-left)">
                  Initial Interior
                  <br />
                  <span tw="font-bold">Treatment</span>
                </H2>
                <div tw="pr-5 sm:(px-0)">
                  <List
                    as="ol"
                    tw="gap-12 text-lg text-gray-700"
                    aria-label="Interior Treatments"
                  >
                    {interiorTreatmentSection.list.map(({ step }) => (
                      <List.Item>{step}</List.Item>
                    ))}
                  </List>
                </div>

                <Img
                  fixed={diagrams.interior.roof.localFile.childImageSharp.fixed}
                  css={[
                    tw`relative flex justify-center md:(absolute bottom-0 right-0 mr-auto) overflow-hidden`,
                    `left: calc((100% - 286px) / 4);
                      @media (min-width: 768px){ 
                        max-width: 286px;
                        left: calc(100% - 286px - 6rem);
                      }`,
                  ]}
                />
                <p tw="italic text-gray-600">
                  {interiorTreatmentSection.footnote}
                </p>
              </div>
              <div tw="col-span-2">
                <Img
                  fixed={
                    diagrams.interior.floorplan.localFile.childImageSharp.fixed
                  }
                  css={[
                    tw`overflow-hidden`,
                    `transform: scale(.75);
                      left: calc((75% - 368px) / 4);
                      @media (min-width: 768px){ 
                        transform: scale(1);
                        left: 0;
                      }
                      `,
                  ]}
                />
              </div>
            </div>
          </Container>
        </section>

        {/* Exterior Treatment Diagram and Content */}
        <section tw="bg-blue-700 text-white py-10">
          <Container tw="lg:(grid grid-cols-2 gap-8)">
            <Img fluid={diagrams.exterior.localFile.childImageSharp.fluid} />
            <div tw="text-white my-10">
              <H2 tw="font-normal uppercase text-white mb-5 text-center sm:(text-left)">
                Initial Exterior
                <br />
                <span tw="font-bold">Treatment</span>
              </H2>
              <List as="ol" tw="text-lg" aria-label="ExteriorTreatments">
                {exteriorTreatmentSection.list.map(({ step }) => (
                  <List.Item>{step}</List.Item>
                ))}
              </List>
            </div>
          </Container>
        </section>

        {/* Numbered Process */}
        <section
          tw="py-16"
          aria-label={`Proven ${
            numberedProcess?.processStep?.length || 4
          }-step Process`}
        >
          <Container>
            <H2 tw="text-center flex items-center justify-center mb-10">
              Our Proven{' '}
              <span tw="rounded-full bg-yellow-600 text-white h-24 w-24 mx-3 flex items-center justify-center text-6xl">
                {numberedProcess.processStep.length}
              </span>{' '}
              Step Process
            </H2>
            <div>
              {numberedProcess.processStep.map(
                ({ headline, subtext, image }) => (
                  <div tw="flex items-center mb-16">
                    <div>
                      <div tw="rounded-full border-yellow-600 border-4 p-6 flex justify-center content-center w-32 h-32 mr-8">
                        <img tw="max-w-full" src={image.localFile.publicURL} />
                      </div>
                    </div>
                    <div tw="flex-shrink">
                      <H4 tw="text-2xl text-gray-800">{headline}</H4>
                      <p tw="text-xl text-gray-600">{subtext}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </Container>
        </section>

        {/* Seasonal Pest Focus */}
        <section tw="bg-gray-50 py-16" aria-label="Seasonal Pest Focus">
          <Container>
            <H2 tw="text-center mb-10">Seasonal Pest Focus</H2>
            <div tw="grid gap-8 md:(grid-cols-2) lg:(grid-cols-4)">
              {seasonalPestFocus.season.map(
                ({ headline, subtext, graphic }) => (
                  <div tw="text-center flex justify-center flex-col">
                    <div tw="flex justify-center content-center h-64">
                      <img
                        alt={headline}
                        tw="mb-10 px-5 lg:(mb-0) xl:(px-20)"
                        src={graphic.localFile.publicURL}
                      />
                    </div>
                    <H4 tw="text-lg uppercase">{headline}</H4>
                    {subtext && <p tw="text-gray-700 text-lg">{subtext}</p>}
                  </div>
                )
              )}
            </div>
          </Container>
        </section>

        <section tw="py-16">
          {/* Frequently Asked Questions */}
          {(faq?.questionList?.length || 0) > 0 && (
            <Container tw="grid grid-cols-1 gap-8 border-b-2 border-gray-200 pb-20">
              <H3 tw="text-5xl">{faq?.headline}</H3>
              <dl>
                {faq?.questionList &&
                  faq.questionList.map(({ question, answer }) => (
                    <div tw="mb-12">
                      <dt tw="text-3xl mb-3">{question}</dt>
                      <dd tw="text-lg leading-relaxed text-gray-800">
                        {answer}
                      </dd>
                    </div>
                  ))}
              </dl>
            </Container>
          )}
        </section>
        <section tw="py-16">
          {/* Testimonials */}
          <Container>
            <Testimonials.Carousel />
          </Container>
        </section>
        {/* Footer CTA */}
        <section>
          <Container>
            <div tw="text-center content-center pt-10">
              <H2 tw="text-5xl">{footerCta.headline}</H2>
              <p tw="text-lg leading-relaxed text-gray-800 lg:(px-10 mx-10) xl:(px-24 mx-24)">
                {footerCta.paragraph}
              </p>

              <div tw="flex justify-center">
                <ButtonLink tw="mt-10 text-2xl" to="/quote">
                  <span tw="mr-3">{footerCta.ctaButtonText}</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </ButtonLink>
              </div>
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        content
        ...FeaturedImage
        pestControlServices {
          hero {
            headline: pestControlServicesHeroHeadline
            subHeadline: pestControlServicesHeroSubHeadline
            paragraph: pestControlServicesHeroParagraph
            ctaButtonText: pestControlServicesHeroCtaButtonText
            background {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ppsMaxSection {
            title: ppsMaxSectionServiceTitle
            frequencyOfService: ppsMaxSectionFrequencyOfService
            subHeadline: ppsMaxSectionSubHeadline
            content: ppsMaxSectionContent
            footnote: ppsMaxSectionFootnote
          }
          interiorTreatmentSection {
            footnote: interiorTreatmentSectionFootnote
            list: interiorTreatmentList {
              step: interiorTreatmentListStep
            }
          }
          exteriorTreatmentSection {
            list: exteriorTreatmentList {
              step: exterirorTreatmentListStep
            }
          }
          diagrams {
            interior {
              floorplan {
                localFile {
                  childImageSharp {
                    fixed(width: 386, height: 454) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    fluid(maxHeight: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              roof {
                localFile {
                  childImageSharp {
                    fixed(width: 286, height: 129) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    fluid(maxHeight: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            exterior {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          numberedProcess {
            processStep {
              headline
              subtext
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
          seasonalPestFocus {
            season {
              headline
              subtext
              graphic {
                localFile {
                  publicURL
                }
              }
            }
          }
          faq {
            headline
            questionList {
              question
              answer
            }
          }
          footerCta {
            headline
            subtext
            buttonText
          }
        }
      }
    }
  }
`
