import React from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
// Used to put guard rails on the content users can put into a blog post.

export default styled('div')`
    & h1, & h2,
    & h3, & h4,
    & h5, & h6 {
        ${ tw`font-bold mb-2 text-gray-900 leading-tight` }

    }
    & h1 {
        ${ tw`text-5xl` }
    }
    & h2 {
        ${ tw`text-4xl` }
    }
    & h3 {
        ${ tw`text-3xl` }
    }
    & h4 {
        ${ tw`text-2xl` }
    }
    & h5 {
        ${ tw`text-xl` }
    }
    & p {
        margin-bottom: 1rem;
    }
    & a {
        ${tw`text-blue-700 underline`}

        & :hover,
        & :focus {
            ${tw`text-blue-400`}
        }
        & :active {
            ${tw`text-blue-900`}
        }
    }

    & figure.wp-block-embed.is-type-video {
        margin: 1em auto 2em;
        padding-bottom: 56.25%;
        ${ tw`relative bg-gray-100 h-0` }
        & > div {
            display: flex;
            justify-content: center;
            & > iframe {
                ${ tw`absolute top-0 left-0 w-full h-full` }

            }
            
        }
        & > figcaption {
            padding: 1rem;
        }
        
    }
    & > ol {
        ${ tw`list-decimal ml-6` }

        & > li {
            ${ tw`pl-2 mb-2`}
        }
    }
    & > ul {

        ${ tw`list-none p-0 mb-4` }

        & > li {
            ${ tw`flex relative pl-5 mb-2` }
            
            &:before {
                content: '';

                ${ tw`absolute bg-yellow-600 mr-5 rounded-full left-0 mt-3 w-2 h-2` }
            }
        }
    }  
    & > blockquote {
        ${ tw`bg-gray-50 border-yellow-600 border-0 border-l-8 py-2 px-6 my-8` }

        & > cite { 
            ${ tw`flex mt-4 text-right` } 
        }
    }
    & > .wp-block-table{
        ${ tw`my-5` }

        & table {
            ${ tw`border-collapse` }
        }

        & table,
        & tbody,
        & tr{
            ${ tw`w-full` }
        }

        & td {
            ${ tw`border-2 border-gray-100 py-2 px-4` }
        }
    }
    & .wp-block-separator {
        ${ tw`mt-5 border-t-4 border-gray-100` }
    }
`
