import styled from '@emotion/styled'

import '../styles/global.css'
import tw from 'twin.macro'

/*
    Custom Bullet Points, as demo'ed on CSS-Tricks
    https://css-tricks.com/how-to-make-a-list-component-with-emotion/

    written by Robin Rendle
    https://css-tricks.com/author/robinrendle/
*/

const List = styled('ul')`
  list-style: none;
  padding: 0;
  counter-reset: react-list;
`

List.Item = styled('li')`
  display: flex;
  position: relative;

  counter-increment: react-list;
  ul & {
    padding-left: 1.5em;
    &:before {
      content: '';
      position: absolute;
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      margin-right: 15px;
      background-color: #ffd100; /* yellow 600 */
      left: 0;
      top: 50%;
      translate: translateY(-50%);
    }
  }

  ol & {
    ${tw`pl-12 mb-8`}
    &:before {
      content: '' counter(react-list);
      ${tw`rounded-full h-8 w-8 left-0 top-0 bg-yellow-600 text-gray-800 text-xl text-center absolute`}
    }
  }
`

export default List
